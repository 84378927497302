import {
  faFacebookF,
  faGoogle,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import * as styles from "./index.module.scss"
import constants from "../../constants"

export default function Header({ children }) {
  return (
    <Navbar expand="xl" variant="dark">
      <Container>
        <Link className={`flex-fill ${styles.link}`} to="/">
          <Navbar.Brand className="font-weight-bold" id={styles.navbarBrand}>
            <span className="d-none d-sm-inline">Mark Sterling </span>
            <span className="text-light-green">Gober</span>
            <span className="d-none d-sm-inline">, LLC</span>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="navbar-collapse" />
        <Navbar.Collapse id="navbar-collapse">
          <Nav className="flex-fill">
            <Link
              activeClassName="text-light-green"
              className={styles.link}
              to="/"
            >
              <Nav.Link as="span">Home</Nav.Link>
            </Link>
            <Link
              activeClassName="text-light-green"
              className={styles.link}
              to="/legal-services"
            >
              <Nav.Link as="span">Legal Services</Nav.Link>
            </Link>
            <Link
              activeClassName="text-light-green"
              className={styles.link}
              to="/our-practice"
            >
              <Nav.Link as="span">Our Practice</Nav.Link>
            </Link>
            <Link
              activeClassName="text-light-green"
              className={styles.link}
              to="/hi-im-mark"
            >
              <Nav.Link as="span">Hi, I'm Mark</Nav.Link>
            </Link>
            <Link
              activeClassName="text-light-green"
              className={styles.link}
              to="/contact"
            >
              <Nav.Link as="span">Contact</Nav.Link>
            </Link>
          </Nav>
          <div className="mt-2 ml-xl-4 mb-1 mb-xl-0">
            <Link to={constants.facebookUrl}>
              <FontAwesomeIcon
                icon={faFacebookF}
                id={styles.facebookIcon}
                size="2x"
              />
            </Link>
            <Link className="ml-3" to={constants.googleUrl}>
              <FontAwesomeIcon
                icon={faGoogle}
                id={styles.twitterIcon}
                size="2x"
              />
            </Link>
            <Link className="ml-3" to={constants.linkedinUrl}>
              <FontAwesomeIcon
                icon={faLinkedinIn}
                id={styles.linkedinIcon}
                size="2x"
              />
            </Link>
            <Link className="ml-3" to={constants.aavoUrl}>
              <StaticImage
                alt="Avvo"
                placeholder="blurred"
                src="../../images/aavo.svg"
                width={31}
              />
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
